import {
    Alert,
    Avatar,
    Badge,
    Dialog,
    Flex,
    Group,
    Loader,
    Notification,
    rem,
    rgba,
    Stack,
    Switch,
    Text,
    TextInput,
    useMantineTheme,
} from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { useLocalStorage } from '@mantine/hooks'
import {
    IconCheck,
    IconClipboard,
    IconHeart,
    IconLink,
    IconLinkOff,
    IconMedal2,
    IconQuestionMark,
} from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import ButtonWithAds from '@/components/ads/ButtonWithAds'
import SupportedSitesSlider from '@/components/index/SupportedSitesSlider'
import { APIList } from '@/features/api/types'
import { parseLink } from '@/features/wrappedBypass'
import { useAds } from '@/provider/useAds'
import { useApi } from '@/provider/useApi'
import { cutOfString } from '@/utils/cutOf'
import { siteIcon } from '@/utils/siteIcon'

import config from '../config'

enum EAdPopUp {
    initial = 'initial',
    shown = 'shown',
}

enum DetectHostStatus {
    INITIAL = 'INITIAL',
    SUPPORTED = 'SUPPORTED',
    UNSUPPORTED = 'UNSUPPORTED',
    NOT_URL = 'NOT_URL',
}

const useStyles = createStyles((theme) => ({
    link: {
        // glowing background shadow input
        background: 'transparent',
        border: 'none',
        outline: 'none',
        boxShadow: `0 0 50px ${theme.colors.blue[8]}, 0 0 100px ${theme.colors.blue[9]}, 0 0 200px ${theme.colors.blue[9]}`,
        borderRadius: '10px',
        padding: '10px',

        transition: 'all 0.5s ease',
        // animation: `${glow(theme.colors.blue[9])} 20s ease 2s infinite forwards`,
        '&:hover': {
            boxShadow: `0 0 10px ${theme.colors.blue[8]}, 0 0 60px ${theme.colors.blue[9]}, 0 0 140px ${theme.colors.blue[9]}`,
        },
    },
}))

function LinkComponent({
    preloadData,
}: React.PropsWithChildren<{
    preloadData: APIList[]
}>) {
    const theme = useMantineTheme()
    const { fetchList } = useApi()
    const { classes } = useStyles()

    const [autoRedirect, setAutoRedirect] = useLocalStorage({
        key: 'is-set-auto-redirect',
        defaultValue: false,
    })
    const [link, setLink] = useState('')
    const [clipboardError, setClipboardError] = useState(false)
    const { push } = useRouter()
    const adsContext = useAds()

    const [bypassHost, setBypassHost] = useState<string>('')
    const [detectHostStatus, setDetectHostStatus] = useState<DetectHostStatus>(DetectHostStatus.INITIAL)
    const [bypassRequested, setBypassRequested] = useState(EAdPopUp.initial)

    const { data } = useQuery({
        queryKey: ['list'],
        gcTime: 5 * 1000,
        queryFn: () => fetchList(),
        initialData: preloadData,
    })

    const setLInkFn = (link: string) => {
        setLink(link)
        detectHost(link)
    }

    const leftIcon = () => {
        if (detectHostStatus === DetectHostStatus.SUPPORTED) {
            return <Avatar src={siteIcon(link)} size='1rem' alt={bypassHost} />
        } else if (detectHostStatus === DetectHostStatus.UNSUPPORTED) {
            return <IconQuestionMark size='1rem' stroke={2.5} color={theme.colors.orange[4]} />
        } else if (detectHostStatus === DetectHostStatus.NOT_URL) {
            return <IconLinkOff size='1rem' stroke={2.5} color={theme.colors.red[4]} />
        } else {
            return undefined
        }
    }

    const infoText = () => {
        if (detectHostStatus === DetectHostStatus.SUPPORTED) {
            return (
                <Badge color='teal' mb='xs' leftSection={<IconCheck size='1rem' />}>
                    <b>{bypassHost}</b> is supported
                </Badge>
            )
        } else if (detectHostStatus === DetectHostStatus.UNSUPPORTED) {
            return (
                <Badge color='orange' mb='xs'>
                    {`${bypassHost} is probably not supported`}
                </Badge>
            )
        } else if (detectHostStatus === DetectHostStatus.NOT_URL) {
            return (
                <Badge color='red' mb='xs'>
                    Not a valid URL
                </Badge>
            )
        } else {
            return undefined
        }
    }

    const detectHost = (url: string) => {
        try {
            if (url === '') {
                setDetectHostStatus(DetectHostStatus.INITIAL)
                return
            }
            const urlObj = new URL(url)
            const host = urlObj.host
            if (!host) throw new Error('No host')

            setBypassHost(host)
            setDetectHostStatus(DetectHostStatus.UNSUPPORTED)

            data.forEach((service) => {
                if (service.domains.includes(host)) {
                    setDetectHostStatus(DetectHostStatus.SUPPORTED)
                }
            })
        } catch (e) {
            setDetectHostStatus(DetectHostStatus.NOT_URL)
        }
    }

    const bypassFn = (targetLink: string) => {
        push(parseLink(targetLink))
        setBypassRequested(EAdPopUp.shown)
    }

    const insertClipboard = async () => {
        try {
            await navigator.permissions.query({
                name: 'clipboard-read',
            } as unknown as PermissionDescriptor)
            navigator.clipboard.readText().then((text) => {
                bypassFn(text)
            })
        } catch (e) {
            setClipboardError(true)
        }
    }

    if (bypassRequested === EAdPopUp.initial) {
        return (
            <Stack>
                <Dialog opened={clipboardError} shadow='0' bg='transparent'>
                    <Notification
                        onClose={() => {
                            setClipboardError(false)
                        }}
                        color='red'
                        title='Could not access clipboard!'
                    >
                        Your browser (like Firefox) does not support clipboard access or you have not
                        given us permission to access it.
                    </Notification>
                </Dialog>

                <SupportedSitesSlider preloadData={preloadData.map((list) => list.name)} />

                {/*<Flex align='center' direction='column' wrap='wrap'>*/}
                {/*    <Badge variant='dot' size='lg'>*/}
                {/*        Enter a link.*/}
                {/*    </Badge>*/}
                {/*</Flex>*/}

                <TextInput
                    className={classes.link}
                    value={link}
                    sx={(theme, u) => ({
                        [u.dark]: {
                            borderColor: theme.colors.dark[4],
                        },
                        [u.light]: {
                            borderColor: theme.colors.gray[4],
                        },
                        [u.largerThan('sm')]: {
                            maxWidth: '400px',
                            minWidth: '200px',
                        },
                    })}
                    label={infoText()}
                    leftSection={leftIcon()}
                    onChange={(value) => {
                        setLInkFn(value.target.value)
                    }}
                    placeholder='enter a link to get started'
                />

                <ButtonWithAds
                    radius='md'
                    size='md'
                    id='bypass-button'
                    variant='gradient'
                    gradient={{ from: 'indigo', to: 'cyan' }}
                    url={parseLink(link)}
                    sx={{
                        paddingRight: rem(14),
                        height: rem(48),
                    }}
                >
                    Bypass Link !
                </ButtonWithAds>

                <Group grow>
                    <ButtonWithAds
                        radius='md'
                        onClick={insertClipboard}
                        size='compact-md'
                        id='clipboard-button'
                        variant='subtle'
                        leftSection={<IconClipboard size='1rem' />}
                    >
                        From Clipboard
                    </ButtonWithAds>
                    <Switch
                        checked={autoRedirect}
                        onChange={(event) => {
                            setAutoRedirect(event.currentTarget.checked)
                        }}
                        color='dark'
                        onLabel={<IconLink size='1rem' stroke={2.5} color={theme.colors.yellow[4]} />}
                        offLabel={<IconLinkOff size='1rem' stroke={2.5} color={theme.colors.blue[6]} />}
                        label='Auto-Redirect'
                    />
                </Group>

                <Alert
                    onClick={() => {
                        setLInkFn(config.example_link)
                    }}
                    sx={(theme, u) => ({
                        cursor: 'pointer',
                        '&:hover': {
                            [u.dark]: {
                                background: rgba(theme.colors.cyan[8], 0.4),
                            },
                            [u.light]: {
                                background: rgba(theme.colors.cyan[3], 0.4),
                            },
                        },
                    })}
                    icon={<IconMedal2 size='1rem' />}
                    variant='light'
                    color='cyan'
                    radius='md'
                    title='Try an example link!'
                >
                    We can bypass links most other bypasses can&apos;t. <br />
                    Try the Example!
                </Alert>
            </Stack>
        )
    } else {
        return (
            <Stack>
                <Text c='dimmed' size='sm' ta='center'>
                    bypassing {cutOfString(link, 15)}...
                </Text>
                <Flex
                    mt='md'
                    mb='md'
                    gap='md'
                    justify='center'
                    align='center'
                    direction='row'
                    wrap='wrap'
                >
                    <Loader size='lg' />
                </Flex>
                <Alert
                    icon={<IconHeart size='1rem' />}
                    variant='light'
                    color='violet'
                    radius='md'
                    title='Thank you for viewing ads!'
                >
                    Ads will only be shown sometimes per session.
                </Alert>
            </Stack>
        )
    }
}

export default LinkComponent
