import { Anchor, Card, Flex, List, rem, Text, ThemeIcon, useMantineTheme } from '@mantine/core'
import { IconCircleCheck, IconExternalLink, IconLink } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import { ApiSupportedSitesResponse } from '@/features/api/types'
import { useApi } from '@/provider/useApi'

import classes from './Feature.module.css'

export interface ISupportedSites {
    preloadData?: ApiSupportedSitesResponse[]
}

export default function FeatureCardSupportedSites({
    preloadData,
}: React.PropsWithChildren<ISupportedSites>) {
    const { fetchSupportedSites } = useApi()
    const theme = useMantineTheme()

    const { data } = useQuery({
        queryKey: ['supported-sites'],
        queryFn: fetchSupportedSites,
    })

    return (
        <Card key='Supported Websites' shadow='md' radius='md' className={classes.card} padding='xl'>
            <Flex gap='md' align='center' direction='row' wrap='wrap'>
                <IconLink
                    style={{ width: rem(40), height: rem(40) }}
                    stroke={2}
                    color={theme.colors.blue[6]}
                />
                <Anchor href='/supported'>
                    <Text fz='xl' fw={500} className={classes.cardTitle} mt='xs'>
                        Supported Websites <IconExternalLink size='1rem' />
                    </Text>
                </Anchor>
            </Flex>

            <List
                mt={'sm'}
                spacing='xs'
                size='sm'
                sx={(_, u) => ({
                    [u.largerThan('md')]: {
                        overflowY: 'scroll',
                        height: rem(135),
                        width: '100%',
                    },
                })}
                icon={
                    <ThemeIcon color='teal' size={24} radius='xl'>
                        <IconCircleCheck size='1rem' />
                    </ThemeIcon>
                }
            >
                {(data || preloadData || []).map((site) => (
                    <List.Item key={site}>{site}</List.Item>
                ))}
            </List>
        </Card>
    )
}
